<template>
    <div class="pageBox">
        <!-- <div class="topBox">
            
        </div> -->
        <div class="boxTable">
            <div :class="`${query.type==1?'leftBox':''}`">
                <div class="title areaTitle">
                    <div style="display:flex;justify-content:space-between;align-items:center">
                        用户列表
                        <el-radio-group @change="changeType" v-model="query.type" style="margin-left:20px;" size="large">
                            <el-radio-button :label="0">游客</el-radio-button>
                            <el-radio-button :label="1">内部用户</el-radio-button>
                        </el-radio-group>
                    </div>
                    <el-button v-if="query.type" color="#803a15" dark @click="oper(0,null)">
                        <svg-icon icon-class="plus"/>&nbsp;&nbsp;添加
                    </el-button>
                </div>
                <div class="boxP">
                    <el-table 
                    v-loading="loading"
                    @row-click="rowClick"
                    size="large" 
                    :data="tableData"
                    :highlight-current-row="true" 
                    style="width: 100%">
                        <el-table-column prop="username" label="账号" align="center" />
                        <el-table-column prop="mobile" label="手机号"  align="center" />
                        <el-table-column prop="email" label="邮箱" align="center" />
                        <el-table-column label="状态" v-if="query.type==1"  align="center">
                            <template #default="scope">
                                <el-switch
                                v-model="scope.row.status"
                                :disabled="scope.row.id == 1"
                                @change="changeStatu($event,scope.row)"
                                :inactive-value="0"
                                :active-value="1"
                                active-text="启用"
                                inactive-text="禁用"
                                />
                            </template>
                        </el-table-column>
                        <!-- <el-table-column prop="createTime" label="创建日期" align="center" /> -->
                        <el-table-column label="操作" width="240" align="center" v-if="query.type==1">
                            <template #default="scope">
                                <el-button type="success" size="small" plain @click="oper(1,scope.row)">编辑</el-button>
                                <el-button type="danger" size="small"  :disabled="scope.row.id == 1" @click="del(scope.row.id)" plain>删除</el-button>
                                <el-button type="primary" size="small" @click="resetPwd(scope.row.id)" plain>重置密码</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination 
                    background 
                    :page-size="query.size"
                    :current-page="query.page"
                    layout="total, prev, pager, next"
                    @current-change="pageChange" 
                    :total="total" />
                </div>
            </div>
            <div class="rightBox" v-if="query.type==1">
                <div class="title areaTitle">
                    <div>区域分配</div>
                    <div>
                        <el-button @click="saveLinkZone" :disabled="userId&&currentKey?false:true" color="#803a15" dark size="small">
                            <svg-icon icon-class="check"/>&nbsp;保存
                        </el-button>
                    </div>
                </div>
                <div class="boxP">
                    <el-tree 
                    :data="treedata" 
                    ref="treeRef"
                    :default-expand-all="true"
                    :highlight-current="true"
                    node-key='code'
                    @node-click="handleNodeClick"
                    :current-node-key="currentKey"
                    :expand-on-click-node="false"
                    :props="defaultProps">
                        <template #default="{ node, data }">
                            <span>
                            <span>{{ node.label }}</span>
                            <span v-if="data.code == currentKey" style="color:green;margin-left:10px">
                               <svg-icon icon-class="check"/>
                            </span>
                            </span>
                        </template>
                    </el-tree>
                </div>
            </div>
        </div>
        <oper-form ref="operForm" @OK="CX"/>
        <operPwd ref="operPwd"/>
    </div>
</template>
<script>
import operForm from './Modules/operpage.vue'
import operPwd from './Modules/operPwd.vue'
import { getAllUser, updateStatusUser, delUser, linkZone } from '@/api/user.js'
import { listZone } from '@/api/area.js'
export default {
    components:{
        operForm,
        operPwd
    },
    data(){
        return{
            query:{
                type:1,
                page:1,
                size:10,
            },
            total:0,
            tableData:[],
            loading:false,
            defaultProps:{
                children: 'children',
                label: 'name',
            },
            currentKey:null,
            userId:null,
            treedata:[]
        }
    },
    mounted(){
        this.CX(0)
        this.GetlistZone()
    },
    methods:{
        changeType(val){
            this.query.type = val
            this.CX(0)
        },
        pageChange(page){
            this.query.page = page
            this.CX(1)
        },
        GetlistZone(){
            this.loading = true
            listZone().then(r=>{
                this.loading = false
                this.treedata = r.data.lists
            })
        },
        CX(type){
            if(type == 0){
                this.query.page = 1
            }
            getAllUser(this.query).then(r=>{
                this.tableData = r.data.data
                this.total = r.data.pageInfo.total
            })
        },
        rowClick(data){
            this.userId = data.id
            this.currentKey = data.zoneId
        },
        handleNodeClick(data){
            this.currentKey = data.code
        },
        changeStatu(e,data){
           this.$confirm(
                '此操作将会修改状态信息，是否继续?',
                'Warning',
                {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                }
            ).then(() => {
                var para={
                    id:data.id,
                    status:e
                }
                updateStatusUser(para).then(r=>{
                    this.$message({
                        type: 'success',
                        message: '修改成功!'
                    });
                    this.CX(1)
                })
            })
            .catch((err) => {
                if(e == 1){
                    data.status = 0
                }else{
                    data.status = 1
                }
            }) 
        },
        oper(type,data){
            this.$refs.operForm.open(type,data)
        },
        del(id){
            this.$confirm(
                '此操作将永久删除该信息，是否继续?',
                'Warning',
                {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                }
            ).then(() => {
               delUser(id).then(r=>{
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    
                    this.CX(0)
                })
            })
            .catch(() => {
                
            })
        },
        resetPwd(id){
            this.$refs.operPwd.open(id)
        },
        saveLinkZone(){
            let query = {
                userID:this.userId,
                zoneID:this.currentKey
            }
            linkZone(query).then(r=>{
                this.$message.success("区域分配成功")
                this.GetAllUser()
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.topBox{
    width: 100%;
    margin-bottom: 20px;
}
.boxTable{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .title{
        line-height: 60px;
        border-bottom: 1px solid #CFCFCF;
        color: #854933;
        font-size: 20px;
        padding: 0px 20px;
        box-sizing: border-box;
    }
    .areaTitle{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .boxP{
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
    }
    .leftBox{
        width: calc(100% - 420px);
        background: white;
        border-radius: 5px;
    }
    .rightBox{
        width: 400px;
        background: white;
        border-radius: 5px;
    }
}
.pageBox{
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
}
.el-pagination{
    margin-top: 20px;
    justify-content: center;
}
/deep/.el-radio-button__original-radio:checked+.el-radio-button__inner{
    background-color:#803a15; 
    border-color:#803a15;
    box-shadow: -1px 0 0 0 #803a15;
}
/deep/.el-pagination.is-background .el-pager li.is-active{
    background-color: #803a15;
}
</style>